import React from 'react'
import { alignContent,bottomSpacing, addSlashInternalLink, addSlashButtonLink } from '../../helper'
import SearchListenerBlock from '../Blocks/search-block/SearchListenerBlock'
import SearchUseCaseBlock from '../Blocks/search-block/SearchUseCaseBlock'
import { Link } from 'gatsby'

const HeadlineAndBodyTextBlock = ({ headlines, body, button, showButton, width, addSearch, searchBox, customClass = '' }) => {
    const widthClass = width === 'Small' ? 'bodytext-width-small' : (
        width === 'Medium' ? 'bodytext-width-medium' : (
			width === 'Medium Large' ? 'bodytext-width-medium-large' : (
				width === 'Large' ? 'bodytext-width-large' : ''
			)
		)
    )
    return (
        <div className={`headline-bodytext-wrapper ${customClass} ${widthClass}`}>
            {
                headlines && headlines.map((headline, index) => {
                    var headlineValueContent = headline.headlineValue;

                    if (!headlineValueContent) return;

                    switch (headline.headlineType) {
                        case 'H1':
                            return (
                                <h1 key={index} className={alignContent(headline.headlineAlignment)} dangerouslySetInnerHTML={{ __html: addSlashInternalLink(headlineValueContent) }}></h1>
                            )
                        case 'H2':
                            return (
                                <h2 key={index} className={alignContent(headline.headlineAlignment)} dangerouslySetInnerHTML={{ __html: addSlashInternalLink(headlineValueContent) }}></h2>
                            )
                        case 'H3':
                            return (
                                <h3 key={index} className={alignContent(headline.headlineAlignment)} dangerouslySetInnerHTML={{ __html: addSlashInternalLink(headlineValueContent) }}></h3>
                            )
                        case 'H4':
                            return (
                                <h4 key={index} className={alignContent(headline.headlineAlignment)} dangerouslySetInnerHTML={{ __html: addSlashInternalLink(headlineValueContent) }}></h4>
                            )
                        case 'H5':
                            return (
                                <h5 key={index} className={alignContent(headline.headlineAlignment)} dangerouslySetInnerHTML={{ __html: addSlashInternalLink(headlineValueContent) }}></h5>
                            )
                        default:
                            return (
                                <h6 key={index} className={alignContent(headline.headlineAlignment)} dangerouslySetInnerHTML={{ __html: addSlashInternalLink(headlineValueContent) }}></h6>
                            )
                    }
                })

            }
            {
                body && body.map((body, index) => {
                    return (
                        <div key={index} className={`bodyCopy ${alignContent(body.bodyCopyAlignment)} ${bottomSpacing(body.howTallYouWantTheSpaceToBe)}`} dangerouslySetInnerHTML={{ __html: addSlashInternalLink(body.bodyCopy) }}></div>
                    )
                })
            }
            {
                addSearch && (
                    searchBox.searchType === 'Use Cases' ? <SearchUseCaseBlock headline={searchBox.headlineSearch} /> : (
                        <SearchListenerBlock headline={searchBox.headlineSearch} />
                    )
                )
            }
            {
                showButton && button && button.title && (
                    <div className="button-layout">
                        <Link className="button" to={addSlashButtonLink(button.url)} target={button.target ? button.target : '_self'}>{button.title}</Link>
                    </div>
                )
            }
        </div>
    )
}

export default HeadlineAndBodyTextBlock
